.fc-event-main:hover {
  cursor: pointer;
  background-color: #4d94ff;
}

.fc-daygrid-day-frame,
fc-scrollgrid-sync-inner {
  overflow-y: auto;
  max-height: 125px;
}

.fc-toolbar-title {
  font-size: 12px;
  font-family: "Maven Pro", sans-serif;
}

.fc-header-toolbar.fc-toolbar {
  padding-left: 5px;
  padding-right: 5px;
}

.fc-col-header-cell-cushion {
  font-size: 13px;
  font-family: "Maven Pro", sans-serif;
}

.fc-daygrid-day-number {
  font-size: 13px;
  font-family: "Maven Pro", sans-serif;
}

.fc-event-title.fc-sticky {
  font-size: 13px;
  font-family: "Maven Pro", sans-serif;
}

.fc-today-button.fc-button.fc-button-primary {
  font-size: 13px;
  font-family: "Maven Pro", sans-serif;
}

.fc-next-button.fc-button.fc-button-primary {
  font-size: 13px;
}

.fc-prev-button.fc-button.fc-button-primary {
  font-size: 13px;
}

.fc-title {
  font-size: 13px;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* FONT */
@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Lato&family=Raleway:wght@200;300&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Encode+Sans+SC:wght@100;300;500&family=Roboto&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Abril+Fatface&family=Maven+Pro&display=swap");
